import axios from 'axios';
import Auth from '../services/FirebaseAuth';
import config from '../config';
import { notifyAPIError } from '../services/ErrorMonitoring';
import { generateExternalUrlQueryPath } from '../utils';

async function getCourseList(data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/courses/list`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching course list' });
    return null;
  }
}

async function getCourseById(courseId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/courses/${courseId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching course', courseId });
    return null;
  }
}

async function createCourse(data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/courses`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while creating course' });
    return null;
  }
}

async function editCourse({ courseId, data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/courses/${courseId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while editing course' });
    return null;
  }
}

async function approveCourse(courseId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/courses/${courseId}/approve`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while approve course' });
    return null;
  }
}

async function deleteCourse(courseId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/courses/${courseId}/`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while approve course' });
    return null;
  }
}

function getCoursePublicUrl(
  coach,
  course,
  { referralCode, referralType = 'coach_payout_course' } = {}
) {
  if (!course || !coach) return null;
  const { slug } = course;
  const { slug: coachSlug } = coach;
  if (!slug || !coachSlug) return null;

  const path = `${config.userWeb}/coaches/${coachSlug}/courses/${slug}`;
  const query = {};
  if (referralCode) {
    query.referralCode = referralCode;
    query.referralType = referralType;
  }
  return generateExternalUrlQueryPath(path, query);
}

async function addCourseToCommunity({ courseId, communityId, data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/courses/${courseId}/${communityId}/attach`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while adding course to community',
    });
    return null;
  }
}

async function removeCourseFormCommunity({ courseId }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/courses/${courseId}/community`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while removing from course to community',
    });
    return null;
  }
}

async function removeCoachFromCourse({ courseCoachId }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/courses/coaches/${courseCoachId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while adding coach to course',
    });
    return null;
  }
}
async function addCoachToCourse({ data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/courses/coaches`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while removing coach from course',
    });
    return null;
  }
}

const getInitialCoaches = (coaches = []) => {
  if (coaches?.length === 0) return [];
  const filteredCoach = coaches.filter((item) => item?.role !== 'owner');
  const coachIdList = filteredCoach?.map((item) => item?.coachId);
  return coachIdList;
};

const isCourseApproved = (course) => {
  if (!course) return false;
  return course.approved;
};

export {
  approveCourse,
  deleteCourse,
  getCourseById,
  getCourseList,
  createCourse,
  editCourse,
  getCoursePublicUrl,
  addCoachToCourse,
  addCourseToCommunity,
  getInitialCoaches,
  removeCoachFromCourse,
  removeCourseFormCommunity,
  isCourseApproved,
};
