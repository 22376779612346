const features = {
  PLATFORM_ACCESS: 'PLATFORM_ACCESS',
  ONBOARDING: 'ONBOARDING',
  UPLOAD: 'UPLOAD',
  VIEW_ALL_CHANNELS: 'VIEW_ALL_CHANNELS',
  VIEW_ALL_TRACKS: 'VIEW_ALL_TRACKS',
  VIEW_CHANNEL_ANALYTICS: 'VIEW_CHANNEL_ANALYTICS',
  EDIT_CHANNEL: 'EDIT_CHANNEL',
  VIEW_TRACK_ANALYTICS: 'VIEW_TRACK_ANALYTICS',
  EDIT_TRACK: 'EDIT_TRACK',
  REVIEW: 'REVIEW',
  REVIEW_ALL_LOCALES: 'REVIEW_ALL_LOCALES',
  REVIEW_COACH: 'REVIEW_COACH',
  REVIEW_EDIT: 'REVIEW_EDIT',
  REVIEW_EDIT_AUDIO: 'REVIEW_EDIT_AUDIO',
  TRACK_REVIEW_MOVE: 'TRACK_REVIEW_MOVE',
  TRACK_REVIEW_MOVE_TO_REVIEW: 'TRACK_REVIEW_MOVE_TO_REVIEW',
  TRACK_REVIEW_MOVE_TO_AUDIO_EDIT: 'TRACK_REVIEW_MOVE_TO_AUDIO_EDIT',
  TRACK_REVIEW_MOVE_TO_APPROVE: 'TRACK_REVIEW_MOVE_TO_APPROVE',
  TRACK_REVIEW_DELETE: 'TRACK_REVIEW_DELETE',
  VIEW_ALL_COACHES: 'VIEW_ALL_COACHES',
  COACHING: 'COACHING',
  VIEW_SERIES_ANALYTICS: 'VIEW_SERIES_ANALYTICS',
  EDIT_SERIES: 'EDIT_SERIES',
  ADMIN: 'ADMIN',
  ADMIN_PAGE: 'ADMIN_PAGE',
  LIVE_SESSION: 'LIVE_SESSION',
  UPLOAD_LIVE_SESSION: 'UPLOAD_LIVE_SESSION',
  EDIT_LIVE_SESSION: 'EDIT_LIVE_SESSION',
  VIDEO_CALL: 'VIDEO_CALL',
  LIVE_INSTAGRAM_LOGIN: 'LIVE_INSTAGRAM_LOGIN',
  ZOOM_AUTHORIZE: 'ZOOM_AUTHORIZE',
  UPLOAD_PODCAST: 'UPLOAD_PODCAST',
  UPLOAD_PROGRAM: 'UPLOAD_PROGRAM',
  SHOW_LOGOUT_OPTION: 'SHOW_LOGOUT_OPTION',
  EVENTS: 'EVENTS',
  UPLOAD_EVENTS: 'UPLOAD_EVENTS',
  EDIT_EVENT: 'EDIT_EVENT',
  COMMUNITY: 'COMMUNITY',
  COURSES: 'COURSES',
  COURSE_REVIEW_APPROVE: 'COURSE_REVIEW_APPROVE',
  UPLOAD_COMMUNITY: 'UPLOAD_COMMUNITY',
  EDIT_COMMUNITY: 'EDIT_COMMUNITY',
};

module.exports = features;
